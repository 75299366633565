
import Vue, { PropType } from "vue"
import {
  AconexDocumentDataType,
  AconexDocumentSchemaField,
  AconexFormComponentType,
  AconexMandatoryStatus,
} from "@evercam/shared/types/aconex"
import DateTimeField from "@evercam/shared/components/formFields/DateTimeField.vue"
import SwitchField from "@evercam/shared/components/formFields/SwitchField.vue"
import SelectField from "@evercam/shared/components/formFields/SelectField.vue"
import TextField from "@evercam/shared/components/formFields/TextField.vue"

export default Vue.extend({
  name: "AconexForm",
  components: {
    DateTimeField,
    SwitchField,
    SelectField,
    TextField,
  },
  props: {
    value: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    documentSchema: {
      type: Array as PropType<AconexDocumentSchemaField[]>,
      required: true,
    },
    isProgressPhoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: this.value,
    }
  },
  computed: {
    computedDocumentSchema() {
      return this.documentSchema.reduce((acc, field) => {
        // Hidden fields
        if (
          field.identifier === "HasFile" ||
          field.identifier === "Author" ||
          field.identifier === "DocumentNumber" ||
          field.identifier === "AccessList"
        ) {
          return acc
        }
        if (
          this.isProgressPhoto &&
          (field.identifier === "Title" || field.identifier === "RevisionDate")
        ) {
          return acc
        }

        // Select string
        if (
          field.schemaValues &&
          field.dataType === AconexDocumentDataType.String
        ) {
          return [
            ...acc,
            {
              ...field,
              items: field.schemaValues.map((value) => ({
                title: value.value,
                value: value.value,
              })),
              componentType: AconexFormComponentType.Select,
              type: "string",
              mandatoryStatus:
                field.mandatoryStatus === AconexMandatoryStatus.Mandatory,
            },
          ]
        }
        // Select number
        if (field.schemaValues) {
          return [
            ...acc,
            {
              ...field,
              items: field.schemaValues.map((value) => ({
                title: value.value,
                value: value.id,
              })),
              componentType: AconexFormComponentType.Select,
              type: "number",
              mandatoryStatus:
                field.mandatoryStatus === AconexMandatoryStatus.Mandatory,
            },
          ]
        }

        // Boolean
        if (field.dataType === AconexDocumentDataType.Boolean) {
          return [
            ...acc,
            {
              ...field,
              componentType: AconexFormComponentType.Switch,
              mandatoryStatus:
                field.mandatoryStatus === AconexMandatoryStatus.Mandatory,
            },
          ]
        }

        //Datetime
        if (field.dataType === AconexDocumentDataType.Date) {
          return [
            ...acc,
            {
              ...field,
              componentType: AconexFormComponentType.DateTime,
              mandatoryStatus:
                field.mandatoryStatus === AconexMandatoryStatus.Mandatory,
            },
          ]
        }

        // Title
        if (field.identifier === "Title") {
          return [
            {
              ...field,
              componentType: AconexFormComponentType.Text,
              mandatoryStatus:
                field.mandatoryStatus === AconexMandatoryStatus.Mandatory,
            },
            ...acc,
          ]
        }

        // TextField
        if (
          [
            AconexDocumentDataType.String,
            AconexDocumentDataType.Integer,
            AconexDocumentDataType.Long,
          ].includes(field.dataType)
        ) {
          return [
            ...acc,
            {
              ...field,
              componentType: AconexFormComponentType.Text,
              mandatoryStatus:
                field.mandatoryStatus === AconexMandatoryStatus.Mandatory,
            },
          ]
        }

        return [...acc, field]
      }, [])
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$emit("input", this.form)
      },
    },
  },
})
